import { useFlagsmith } from 'flagsmith/react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { Tooltip } from '@/common/components/Tooltip';
import { IAppNavigation } from '@/common/types/AppNavigation';

import { useCollapseSidebar } from '../..';

import * as style from './styles';

type NavItemProps = {
  data: IAppNavigation;
  setHideMenu?: (hideMenu: boolean) => void;
};

export function NavItem({ data, setHideMenu }: NavItemProps) {
  const { t } = useTranslation([
    'sidebar/backofficeMenus',
    'sidebar/nexproMenus',
  ]);
  const { hasFeature } = useFlagsmith();
  const location = useLocation();
  const { collapsed, openSidebar } = useCollapseSidebar();

  const { icon: Icon } = data;

  const { titleIcon: TitleIcon } = data;

  const hasChildren = data?.children?.length;
  const isActive =
    data?.activePaths?.includes(location.pathname) ||
    location.pathname === data?.link;
  const isExternal = data?.external;

  if (data.flag && !hasFeature(data.flag)) return null;

  return (
    <div className={style.menuItem()} key={data.key}>
      {!hasChildren && (
        <Link
          className={style.link({
            active: isActive,
          })}
          to={data.link!}
          onClick={() => {
            if (setHideMenu) {
              setHideMenu(true);
            }
          }}
          target={isExternal ? '_blank' : '_self'}
        >
          {Icon && (
            <Icon
              className={style.icon({
                isActive,
              })}
              size={18}
            />
          )}

          {TitleIcon ? (
            <span className={style.itemTextTitle()}>
              {t(data.title!)}
              <span className={style.profileChevronRight()}>
                <TitleIcon size={12} />
              </span>
            </span>
          ) : (
            <span className={style.itemText()}>{t(data.title!)}</span>
          )}
        </Link>
      )}

      {hasChildren && (
        <Tooltip message={t(data.title)} hideTooltip={!collapsed}>
          <button
            type="button"
            className={style.accordionToggle()}
            onClick={openSidebar}
          >
            <div className={style.accordionTitle()}>
              {Icon && (
                <Icon
                  className={style.icon({
                    isActive,
                  })}
                  size={18}
                />
              )}

              <span className={style.itemText()}>{t(data.title!)}</span>
            </div>

            <ChevronUp className={style.arrowUp()} size={12} />

            <ChevronDown className={style.arrowDown()} size={12} />
          </button>
        </Tooltip>
      )}

      <div className={style.accordionContent()}>
        <ul>
          {collapsed
            ? null
            : data?.children?.map(menuChildren => {
                if (menuChildren.flag && !hasFeature(menuChildren.flag))
                  return null;

                return (
                  <li key={menuChildren.key} className={style.accordionItem()}>
                    <div className={style.verticalDivider()} />

                    <Link
                      className={style.link({
                        active: location.pathname === menuChildren.link,
                      })}
                      onClick={() => {
                        if (setHideMenu) {
                          setHideMenu(true);
                        }
                      }}
                      to={menuChildren.link!}
                    >
                      {t(menuChildren.title!)}
                    </Link>
                  </li>
                );
              })}
        </ul>
      </div>
    </div>
  );
}
